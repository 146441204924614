<template>
  <v-row
    v-if="formDone && processed"
    id="inscription-studio"
    class="final"
    style="overflow: hidden"
  >
    <v-img height="900px" src="@/assets/background.jpg">
      <v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="8" sm="5" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          :md="studio.tattooers.length === 0 ? 6 : 4"
          class="pa-0"
          src="@/assets/background.jpg"
        >
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("consent-form.done") }}<br />
          </h1>
        </v-col>
      </v-row>
    </v-img>
  </v-row>
  <v-container fluid v-else-if="processed" id="consent-form">
    <v-stepper v-model="e1" alt-labels>
      <base-material-card>
        <v-stepper-header>
          <v-btn
            v-if="
              $store.state.auth.user &&
                $store.state.auth.user.id != null &&
                !$vuetify.breakpoint.xsOnly
            "
            outlined
            @click="pop_up ? $emit('close') : $router.back()"
            style="height: 25px; position: absolute"
            class="mr-3"
            elevation="0"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("exit") }}</v-btn
          >
          <v-spacer></v-spacer>

          <v-stepper-step
            step="1"
            :complete="e1 > 1"
            class="pointer"
            @click="e1 = 1"
          >
            <div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
                width: 120%;
                margin-left: -10%;
              "
            >
              {{ $t("consent-form.personal_information") }}
            </div>
          </v-stepper-step>
          <v-divider style="margin-top: 50px !important">></v-divider>
          <v-stepper-step
            step="2"
            :complete="e1 > 2"
            class="pointer"
            @click="e1 = 2"
          >
            <div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("consent-form.general_info") }}
            </div>
          </v-stepper-step>
          <v-divider
            style="margin-top: 50px !important"
            v-if="false"
          ></v-divider>
          <v-stepper-step
            step="3"
            :complete="e1 > 3"
            class="pointer"
            @click="e1 = 3"
            v-if="false"
          >
            <div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
                width: 120%;
                margin-left: -10%;
              "
            >
              {{ $t("consent-form.image_right") }}
            </div>
          </v-stepper-step>

          <v-divider
            v-if="this.section !== null"
            style="margin-top: 50px !important"
          ></v-divider>
          <v-stepper-step
            v-if="this.section !== null"
            step="4"
            :complete="e1 > 4"
          >
            <div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
                width: 120%;
                margin-left: -10%;
              "
            >
              {{ section.name }}
            </div>
          </v-stepper-step>

          <v-spacer></v-spacer>
          <v-row
            v-if="$vuetify.breakpoint.smAndDown"
            style="display: block; width: 100%; margin-top: -30px"
            justify="center"
            ><v-col>
              <div
                v-if="e1 === 1"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("consent-form.general_info") }}
              </div>
              <div
                v-else-if="e1 === 2"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("consent-form.general_info") }}
              </div>
              <div
                v-else
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("consent-form.image_right") }}
              </div>
            </v-col></v-row
          >
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <form
              action
              data-vv-scope="consent-form-personal"
              @submit.prevent="validateFormInitial('consent-form-personal')"
            >
              <!-- Datos cliente-->
              <h2>CLIENTE 
                <v-dialog width="400" v-model="dialogConsentForm">
              <template v-slot:activator="{on}">
              
               <v-btn
                  small
                  icon
                  v-on="on"
                  >
                <v-icon small>mdi-link-variant</v-icon>
                </v-btn></template>
                <v-card id="consentFormLink">
                  <v-card-title
                    style="text-decoration: underline; text-transform: uppercase"
                  >
                    Formulario de consentimiento:
                  </v-card-title>
                  <div class="close">
                    <v-btn icon @click="dialogConsentForm = false">
                      <v-icon id="iconClose">mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-card-text class="pb-2">
                    <v-text-field
                      outlined
                      dense
                      id="URL"
                      hide-details
                      readonly
                      v-model="URL"
                    >
                      <template slot="prepend-inner">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mr-2" v-on="on" @click="copy()">
                              mdi-content-copy
                            </v-icon>
                          </template>
                          {{ $t("copy") }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-text>
                </v-card>
              </v-dialog>
              </h2>
              
              <div class="borderRound pa-4">
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="3">
                    <label>{{ $t("consent-form.name") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      cols="12"
                      sm="6"
                      md="3"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form-personal.first_name')
                      "
                      data-vv-name="first_name"
                      v-model="form.customer.user.first_name"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.surname") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      cols="12"
                      sm="6"
                      md="3"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form-personal.last_name')
                      "
                      data-vv-name="last_name"
                      v-model="form.customer.user.last_name"
                    >
                    </v-text-field
                  ></v-col>

                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.birthdate") }}: </label>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :hide-details="$vuetify.breakpoint.xs"
                          outlined
                          dense
                          :value="
                            form.customer.birthdate
                              ? $d(
                                  new Date(form.customer.birthdate),
                                  'date2digits'
                                )
                              : ''
                          "
                          readonly
                          data-vv-validate-on="custom"
                          v-validate="''"
                          data-vv-name="birthdate"
                          :error-messages="
                            errors.collect('consent-form-personal.birthdate')
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:prepend-inner>
                            <v-icon
                              small
                              class="pt-0 pb-1"
                              style="margin-top: 6px"
                              color="primary"
                            >
                              $appointments
                            </v-icon>
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="form.customer.birthdate"
                        no-title
                        scrollable
                        :max="today"
                        dense
                        color="primary"
                        @input="
                          menu = false;
                          validateOne('consent-form-personal.birthdate');
                        "
                        ref="picker"
                      />
                    </v-menu>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.dni") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      cols="12"
                      sm="6"
                      md="3"
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form-personal.dni')
                      "
                      data-vv-name="dni"
                      v-model="form.customer.dni"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.address") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      data-vv-name="address"
                      :error-messages="
                        errors.collect('consent-form-personal.address')
                      "
                      cols="12"
                      sm="6"
                      md="3"
                      v-model="form.customer.address"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.town") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      data-vv-name="country"
                      :error-messages="
                        errors.collect('consent-form-personal.country')
                      "
                      cols="12"
                      sm="6"
                      md="3"
                      v-model="form.customer.country"
                    ></v-text-field
                  ></v-col>

                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.phone") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      data-vv-name="phone"
                      :error-messages="
                        errors.collect('consent-form-personal.phone')
                      "
                      cols="12"
                      sm="6"
                      md="3"
                      v-model="form.customer.user.phone"
                      type="number"
                      v-on:keypress="isNumber($event)"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.email") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      cols="12"
                      sm="6"
                      md="3"
                      data-vv-validate-on="blur"
                      v-validate="'email'"
                      :error-messages="
                        errors.collect('consent-form-personal.email')
                      "
                      data-vv-name="email"
                      v-model="form.customer.user.email"
                    ></v-text-field
                  ></v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("how_did_you_met_us") }}:</label>
                    <v-autocomplete
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      :items="howMeetItems"
                      dense
                      cols="12"
                      sm="6"
                      md="3"
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form-personal.howMeet')
                      "
                      data-vv-name="howMeet"
                      v-model="form.customer.howMeet"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <!--Dependiendo de la selección, mostrar o no el siguiente formulario-->
                <v-checkbox
                  v-model="form.customer.medical_report"
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                >
                  <template v-slot:label>
                    <div class="mt-2">
                      {{ $t("consent-form.need_medical_report_laser") }}
                    </div>
                  </template>
                </v-checkbox>

                <!--inicio form medico-->
                <!--Formulario médico-->
                <div v-if="form.customer.medical_report">
                  <v-row>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.allergies") }}: </label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="allergies"
                        :error-messages="
                          errors.collect('consent-form-personal.allergies')
                        "
                        v-model="form.customer.allergies"
                      >
                      </v-text-field
                    ></v-col>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.medicines") }}:</label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="medicines"
                        :error-messages="
                          errors.collect('consent-form-personal.medicines')
                        "
                        v-model="form.customer.medicines"
                      >
                      </v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.diseases") }}: </label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="diseases"
                        :error-messages="
                          errors.collect('consent-form-personal.diseases')
                        "
                        v-model="form.customer.diseases"
                      >
                      </v-text-field
                    ></v-col>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.other") }}: </label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="medical_other"
                        :error-messages="
                          errors.collect('consent-form-personal.medical_other')
                        "
                        v-model="form.customer.medical_other"
                      >
                      </v-text-field
                    ></v-col>
                  </v-row>
                </div>
                <!--fin form medico-->

                <!--Dependiendo de la selección, mostrar o no el siguiente formulario-->
                <v-checkbox
                  v-model="form.customer.minus16_incapacitated"
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                >
                  <template v-slot:label>
                    <div class="mt-2">
                      {{ $t("consent-form.customer_minus16_incapacitated") }}
                    </div>
                  </template>
                </v-checkbox>

                <!--Inicio form -16 o incapacitada-->
                <!--Formulario personas incapacitadas / menores de 16-->
                <div
                  v-if="form.customer.minus16_incapacitated"
                  style="margin-bottom: 30px"
                >
                  <v-row>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.legal_tutor_name") }}:</label>

                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="legal_tutor_first_name"
                        :error-messages="
                          errors.collect(
                            'consent-form-personal.legal_tutor_first_name'
                          )
                        "
                        cols="12"
                        sm="6"
                        md="3"
                        v-model="form.customer.legal_tutor.first_name"
                      >
                      </v-text-field
                    ></v-col>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label
                        >{{ $t("consent-form.legal_tutor_surname") }}:
                      </label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="legal_tutor_last_name"
                        :error-messages="
                          errors.collect(
                            'consent-form-personal.legal_tutor_last_name'
                          )
                        "
                        cols="12"
                        sm="6"
                        md="3"
                        v-model="form.customer.legal_tutor.last_name"
                      >
                      </v-text-field
                    ></v-col>

                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.birthdate") }}: </label>

                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            outlined
                            dense
                            data-vv-validate-on="blur"
                            v-validate="''"
                            data-vv-name="legal_tutor_birthdate"
                            :error-messages="
                              errors.collect(
                                'consent-form-personal.legal_tutor_birthdate'
                              )
                            "
                            v-model="form.customer.legal_tutor.birthdate"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon small class="mt-1" color="primary">
                                $appointments
                              </v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          first-day-of-week="1"
                          v-model="form.customer.legal_tutor.birthdate"
                          no-title
                          scrollable
                          color="primary"
                          @input="
                            menu2 = false;
                            validateOne(
                              'consent-form-personal.legal_tutor_birthdate'
                            );
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.dni") }}: </label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        cols="12"
                        sm="6"
                        md="3"
                        data-vv-validate-on="blur"
                        v-validate="''"
                        :error-messages="
                          errors.collect('consent-form-personal.dni')
                        "
                        data-vv-name="dni"
                        v-model="form.customer.legal_tutor.dni"
                      >
                      </v-text-field></v-col
                  ></v-row>
                  <v-row>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.address") }}:</label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="legal_tutor_address"
                        :error-messages="
                          errors.collect(
                            'consent-form-personal.legal_tutor_address'
                          )
                        "
                        cols="12"
                        sm="6"
                        md="3"
                        v-model="form.customer.legal_tutor.address"
                      >
                      </v-text-field
                    ></v-col>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.town") }}:</label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        cols="12"
                        sm="6"
                        md="3"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="legal_tutor_country"
                        :error-messages="
                          errors.collect(
                            'consent-form-personal.legal_tutor_country'
                          )
                        "
                        v-model="form.customer.legal_tutor.country"
                      ></v-text-field
                    ></v-col>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.phone") }}:</label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="legal_tutor_phone"
                        :error-messages="
                          errors.collect(
                            'consent-form-personal.legal_tutor_phone'
                          )
                        "
                        cols="12"
                        sm="6"
                        md="3"
                        v-model="form.customer.legal_tutor.phone"
                        type="number"
                        v-on:keypress="isNumber($event)"
                      >
                      </v-text-field
                    ></v-col>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.email") }}:</label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        cols="12"
                        sm="6"
                        md="3"
                        data-vv-validate-on="blur"
                        v-validate="'email'"
                        :error-messages="
                          errors.collect('consent-form-personal.email')
                        "
                        data-vv-name="email"
                        v-model="form.customer.legal_tutor.email"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0"
                      ><p style="font-size: 12px">
                        {{ $t("consent-form.in_quality_of") }}
                        <v-radio-group
                          cols="12"
                          sm="6"
                          md="2"
                          row
                          data-vv-validate-on="blur"
                          v-validate="''"
                          data-vv-name="tutor_type"
                          :error-messages="
                            errors.collect('consent-form-personal.tutor_type')
                          "
                          v-model="form.customer.legal_tutor.tutor_type"
                        >
                          <v-radio
                            v-bind:label="$t('consent-form.father')"
                            value="father"
                          ></v-radio>
                          <v-radio
                            v-bind:label="$t('consent-form.mother')"
                            value="mother"
                          ></v-radio>
                          <v-radio
                            v-bind:label="$t('consent-form.legal_tutor')"
                            value="legal_representative"
                          ></v-radio>
                        </v-radio-group>
                        {{ $t("consent-form.legal_tutor_agreement") }}
                      </p></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col class="py-0" cols="12" sm="6" md="3"
                      ><label>{{ $t("consent-form.date") }}</label>
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        data-vv-validate-on="blur"
                        v-validate="''"
                        data-vv-name="date"
                        :error-messages="
                          errors.collect('consent-form-personal.date')
                        "
                        cols="12"
                        sm="6"
                        md="3"
                        v-model="form.available_dates[0].date"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <pop-up-firma
                        :form="form"
                        name="clientSignature2"
                        :text="$t('consent-form.client_tutor_signature')"
                      ></pop-up-firma>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="4">
                    <!--Info anexa para el user-->
                    <v-dialog
                      v-model="dialog"
                      :fullscreen="$vuetify.breakpoint.xs"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          v-bind="attrs"
                          v-on="on"
                          :outlined="
                            form.customer.attached_information !== 'true'
                          "
                          elevation="0"
                          block
                          style="
                          margin-bottom: 25px;
                          height: 30px;
                          max-width: 100%;
                        "
                          :color="error.anex ? 'red' : 'primary'"
                        >
                          {{ $t("consent-form.attached_information_dialog") }}
                        </v-btn>
                      </template>

                      <v-card>
                        <div class="close">
                          <v-btn icon @click="dialog = false" small>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                        <v-stepper
                          alt-labels
                          v-model="e2"
                          v-if="sub_service_annex"
                        >
                          <v-stepper-header ref="headerStepper">
                            <v-spacer />
                            <template v-for="(ss, i) in sub_service_annex">
                              <v-divider
                                style="margin-top: 50px !important"
                                :key="i"
                                v-if="i !== 0"
                              ></v-divider>
                              <v-stepper-step :step="i + 1" :key="i">
                                <div
                                  style="
                                  text-align: center;
                                  color: var(--v-primary-base);
                                  text-transform: uppercase;
                                  width: 120%;
                                  margin-left: -10%;
                                "
                                >
                                  {{ ss.title }}
                                </div>
                              </v-stepper-step>
                            </template>
                            <v-spacer />
                          </v-stepper-header>
                          <v-stepper-items>
                            <v-stepper-content
                              :step="i + 1"
                              v-for="(ss, i) in sub_service_annex"
                              :key="i"
                            >
                              <div v-html="ss.text"></div>
                              <form
                                action
                                data-vv-scope="dialog-form"
                                @submit.prevent="
                                  validateFormDialog('dialog-form')
                                "
                                v-if="i + 1 == sub_service_annex.length"
                              >
                                <v-row>
                                  <v-col class="py-0">
                                    <v-row>
                                      <v-checkbox
                                        v-model="
                                          form.customer.attached_information
                                        "
                                        hide-details
                                        class="pl-2"
                                        on-icon="mdi-check-circle"
                                        off-icon="mdi-circle-outline"
                                        true-value="true"
                                        false-value="false"
                                        v-validate="'is:true'"
                                        data-vv-name="attached_information"
                                        :error-messages="
                                          errors.collect(
                                            'dialog-form.attached_information'
                                          )
                                        "
                                        v-bind:label="
                                          $t(
                                            'consent-form.attached_information_agreement'
                                          )
                                        "
                                      ></v-checkbox>
                                    </v-row>
                                    <v-row>
                                      <v-checkbox
                                        class="pl-2"
                                        on-icon="mdi-check-circle"
                                        off-icon="mdi-circle-outline"
                                        v-model="form.session.treatment_tips"
                                        hide-details
                                        true-value="true"
                                        false-value="false"
                                        v-validate="'is:true'"
                                        data-vv-name="treatment_tips"
                                        :error-messages="
                                          errors.collect(
                                            'dialog-form.treatment_tips'
                                          )
                                        "
                                        v-bind:label="
                                          $t(
                                            'consent-form.treatment_tips_agreement'
                                          )
                                        "
                                      ></v-checkbox>
                                    </v-row>
                                  </v-col>
                                </v-row>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    @click="
                                      e2--;
                                      up();
                                    "
                                    outlined
                                    style="width: 100px !important; height: 25px"
                                  >
                                    {{ $t("back") }}
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    style="
                                    width: 100px !important;
                                    color: #363533;
                                    height: 25px;
                                    padding-top: 10px !important;
                                  "
                                    elevation="0"
                                    type="submit"
                                  >
                                    <v-icon
                                      style="margin-right: 5px"
                                      size="14px"
                                      >$save</v-icon
                                    >
                                    {{ $t("save", { name: "" }) }}
                                  </v-btn>
                                </v-card-actions>
                              </form>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  v-if="i == 0"
                                  color="primary"
                                  @click="dialog = false"
                                  outlined
                                  style="
                                  width: 100px !important;
                                  height: 25px;
                                  padding-top: 10px !important;
                                "
                                >
                                  {{ $t("close") }}
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  v-else-if="i + 1 < sub_service_annex.length"
                                  @click="
                                    e2--;
                                    up();
                                  "
                                  outlined
                                  style="width: 100px !important; height: 25px"
                                >
                                  {{ $t("back") }}
                                </v-btn>
                                <v-btn
                                  v-if="i + 1 < sub_service_annex.length"
                                  color="primary"
                                  style="
                                  width: 100px !important;
                                  color: #363533;
                                  height: 25px;
                                  padding-top: 10px !important;
                                "
                                  elevation="0"
                                  @click="
                                    e2++;
                                    up();
                                  "
                                >
                                  {{ $t("next", { name: "" }) }}
                                  <v-icon style="margin-right: 5px" size="14px"
                                    >mdi-arrow-right</v-icon
                                  >
                                </v-btn>
                              </v-card-actions>
                            </v-stepper-content>
                          </v-stepper-items>
                        </v-stepper>
                        <v-stepper alt-labels v-model="e2" v-else>
                          <v-stepper-header ref="headerStepper">
                            <v-stepper-step step="1">
                              <div
                                style="
                                text-align: center;
                                color: var(--v-primary-base);
                                text-transform: uppercase;
                                width: 120%;
                                margin-left: -10%;
                              "
                              >
                                {{
                                  $t("consent-form.attached_information_dialog")
                                }}
                              </div>
                            </v-stepper-step>

                            <v-divider
                              style="margin-top: 50px !important"
                            ></v-divider>
                            <v-stepper-step step="2">
                              <div
                                style="
                                text-align: center;
                                color: var(--v-primary-base);
                                text-transform: uppercase;
                                width: 120%;
                                margin-left: -10%;
                              "
                              >
                                {{ $t("consent-form.treatment_tips_dialog") }}
                              </div>
                            </v-stepper-step>
                            <v-divider
                              style="margin-top: 50px !important"
                            ></v-divider>

                            <v-stepper-step step="3">
                              <div
                                style="
                                text-align: center;
                                color: var(--v-primary-base);
                                text-transform: uppercase;
                                width: 120%;
                                margin-left: -10%;
                              "
                              >
                                {{ $t("consent-form.data_protection") }}
                              </div>
                            </v-stepper-step>
                            <v-row
                              v-if="$vuetify.breakpoint.smAndDown"
                              style="
                              display: block;
                              width: 100%;
                              margin-top: -30px;
                            "
                              justify="center"
                              ><v-col>
                                <div
                                  v-if="e2 === 1"
                                  style="
                                  text-align: center;
                                  color: var(--v-primary-base);
                                  text-transform: uppercase;
                                "
                                >
                                  {{
                                    $t(
                                      "consent-form.attached_information_dialog"
                                    )
                                  }}
                                </div>
                                <div
                                  v-else-if="e2 === 2"
                                  style="
                                  text-align: center;
                                  color: var(--v-primary-base);
                                  text-transform: uppercase;
                                "
                                >
                                  {{ $t("consent-form.treatment_tips_dialog") }}
                                </div>
                                <div
                                  v-else
                                  style="
                                  text-align: center;
                                  color: var(--v-primary-base);
                                  text-transform: uppercase;
                                "
                                >
                                  {{ $t("consent-form.data_protection") }}
                                </div>
                              </v-col></v-row
                            >
                          </v-stepper-header>
                          <v-stepper-items>
                            <v-stepper-content step="1">
                              <p>
                                {{
                                  $t("consent-form.info_anexa.laser.info_laser")
                                }}
                              </p>
                              <p>
                                {{
                                  $t(
                                    "consent-form.info_anexa.laser.info_contraindications.title"
                                  )
                                }}
                              </p>
                              <p>
                                <b>{{
                                  $t(
                                    "consent-form.info_anexa.laser.info_contraindications.title1"
                                  )
                                }}</b>
                              </p>
                              <ul>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li1_2"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li1_3"
                                    )
                                  }}
                                </li>
                              </ul>
                              <p>
                                <b>{{
                                  $t(
                                    "consent-form.info_anexa.laser.info_contraindications.title2"
                                  )
                                }}</b>
                              </p>
                              <ul>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li2_1"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li2_2"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li2_3"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li2_4"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li2_5"
                                    )
                                  }}
                                </li>
                              </ul>
                              <p>
                                <b>{{
                                  $t(
                                    "consent-form.info_anexa.laser.info_contraindications.title3"
                                  )
                                }}</b>
                              </p>

                              <ul>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li3_1"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li3_2"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li3_3"
                                    )
                                  }}
                                </li>
                              </ul>
                              <p>
                                {{
                                  $t(
                                    "consent-form.info_anexa.laser.info_contraindications.title4"
                                  )
                                }}
                              </p>
                              <ul>
                                <li>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_1"
                                      )
                                    }}
                                  </p>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_2"
                                      )
                                    }}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_3"
                                      )
                                    }}
                                  </p>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_4"
                                      )
                                    }}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_5"
                                      )
                                    }}
                                  </p>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_6"
                                      )
                                    }}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_7"
                                      )
                                    }}
                                  </p>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_8"
                                      )
                                    }}
                                  </p>
                                </li>

                                <li>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_9"
                                      )
                                    }}
                                  </p>
                                  <p>
                                    {{
                                      $t(
                                        "consent-form.info_anexa.laser.info_contraindications.li4_10"
                                      )
                                    }}
                                  </p>
                                </li>
                              </ul>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  @click="dialog = false"
                                  outlined
                                  style="
                                  width: 100px !important;
                                  height: 25px;
                                  padding-top: 10px !important;
                                "
                                >
                                  {{ $t("close") }}
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  style="
                                  width: 100px !important;
                                  color: #363533;
                                  height: 25px;
                                  padding-top: 10px !important;
                                "
                                  elevation="0"
                                  @click="
                                    e2++;
                                    up();
                                  "
                                >
                                  {{ $t("next", { name: "" }) }}
                                  <v-icon style="margin-right: 5px" size="14px"
                                    >mdi-arrow-right</v-icon
                                  >
                                </v-btn>
                              </v-card-actions>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                              <p>
                                {{
                                  $t(
                                    "consent-form.info_anexa.laser.info_contraindications.title5"
                                  )
                                }}
                              </p>
                              <ul>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li5_1"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li5_2"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li5_3"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.info_contraindications.li5_4"
                                    )
                                  }}
                                </li>
                              </ul>

                              <p>
                                {{
                                  $t(
                                    "consent-form.info_anexa.laser.treatment_tips_dialog.title_1"
                                  )
                                }}
                              </p>
                              <p>
                                {{
                                  $t(
                                    "consent-form.info_anexa.laser.treatment_tips_dialog.li_1"
                                  )
                                }}
                              </p>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  @click="
                                    e2--;
                                    up();
                                  "
                                  outlined
                                  style="width: 100px !important; height: 25px"
                                >
                                  {{ $t("back") }}
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  style="
                                  width: 100px !important;
                                  color: #363533;
                                  height: 25px;
                                  padding-top: 10px !important;
                                "
                                  elevation="0"
                                  @click="
                                    e2++;
                                    up();
                                  "
                                >
                                  {{ $t("next", { name: "" }) }}
                                  <v-icon style="margin-right: 5px" size="14px"
                                    >mdi-arrow-right</v-icon
                                  >
                                </v-btn>
                              </v-card-actions>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                              <p>
                                {{
                                  $t(
                                    "consent-form.info_anexa.laser.data_protection.conformidad"
                                  )
                                }}
                              </p>
                              <ul>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.data_protection.li_1"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.data_protection.li_2"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.data_protection.li_3"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.data_protection.li_4"
                                    )
                                  }}
                                </li>
                                <li>
                                  {{
                                    $t(
                                      "consent-form.info_anexa.laser.data_protection.li_5"
                                    )
                                  }}
                                </li>
                              </ul>
                              <form
                                action
                                data-vv-scope="dialog-form"
                                @submit.prevent="
                                  validateFormDialog('dialog-form')
                                "
                              >
                                <v-row>
                                  <v-col class="py-0">
                                    <v-row>
                                      <v-checkbox
                                        v-model="
                                          form.customer.attached_information
                                        "
                                        hide-details
                                        class="pl-2"
                                        on-icon="mdi-check-circle"
                                        off-icon="mdi-circle-outline"
                                        true-value="true"
                                        false-value="false"
                                        v-validate="'is:true'"
                                        data-vv-name="attached_information"
                                        :error-messages="
                                          errors.collect(
                                            'dialog-form.attached_information'
                                          )
                                        "
                                        v-bind:label="
                                          $t(
                                            'consent-form.attached_information_agreement'
                                          )
                                        "
                                      ></v-checkbox>
                                    </v-row>
                                    <v-row>
                                      <v-checkbox
                                        class="pl-2"
                                        hide-details
                                        on-icon="mdi-check-circle"
                                        off-icon="mdi-circle-outline"
                                        v-model="form.session.treatment_tips"
                                        true-value="true"
                                        false-value="false"
                                        v-validate="'is:true'"
                                        data-vv-name="treatment_tips"
                                        :error-messages="
                                          errors.collect(
                                            'dialog-form.treatment_tips'
                                          )
                                        "
                                        v-bind:label="
                                          $t(
                                            'consent-form.treatment_tips_agreement'
                                          )
                                        "
                                      ></v-checkbox>
                                    </v-row>
                                  </v-col>
                                </v-row>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    @click="
                                      e2--;
                                      up();
                                    "
                                    outlined
                                    style="width: 100px !important; height: 25px"
                                  >
                                    {{ $t("back") }}
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    style="
                                    width: 100px !important;
                                    color: #363533;
                                    height: 25px;
                                    padding-top: 10px !important;
                                  "
                                    elevation="0"
                                    type="submit"
                                  >
                                    <v-icon
                                      style="margin-right: 5px"
                                      size="14px"
                                      >$save</v-icon
                                    >
                                    {{ $t("save", { name: "" }) }}
                                  </v-btn>
                                </v-card-actions>
                              </form>
                            </v-stepper-content>
                          </v-stepper-items>
                        </v-stepper>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="4">
                    <v-dialog v-model="dialog_image" persistent>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          elevation="0"
                          block
                          :outlined="!form.customer.image_rights"
                          v-on="on"
                          style="
                          margin-bottom: 25px;
                          height: 30px;
                          max-width: 100%;
                        "
                        >
                          {{ $t("consent-form.image_right") }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          {{ $t("consent-form.image_right") }}
                        </v-card-title>
                        <v-card-text>
                          <p
                            v-text="
                              $t('consent-form.image_rights.p1', {
                                studio_name: form.studio.studio_name,
                              })
                            "
                          />
                          <p
                            v-text="
                              $t('consent-form.image_rights.p2', {
                                studio_name: form.studio.studio_name,
                              })
                            "
                          />

                          <v-row>
                            <v-col class="py-0" cols="12" sm="6"
                              ><label>{{ $t("consent-form.name") }}: </label>
                              <v-text-field
                                hide-details
                                cols="12"
                                sm="6"
                                md="3"
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect(
                                    'consent-form-image.first_name'
                                  )
                                "
                                data-vv-name="first_name"
                                v-model="form.customer.user.first_name"
                              >
                              </v-text-field
                            ></v-col>
                            <v-col class="py-0" cols="12" sm="6"
                              ><label>{{ $t("consent-form.surname") }}: </label>
                              <v-text-field
                                hide-details
                                cols="12"
                                sm="6"
                                md="3"
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect('consent-form-image.last_name')
                                "
                                data-vv-name="last_name"
                                v-model="form.customer.user.last_name"
                              >
                              </v-text-field
                            ></v-col>
                            <v-col class="py-0" cols="12" sm="6"
                              ><label>{{ $t("consent-form.dni") }}: </label>
                              <v-text-field
                                hide-details
                                outlined
                                dense
                                cols="12"
                                sm="6"
                                md="3"
                                data-vv-validate-on="blur"
                                v-validate="'required'"
                                :error-messages="
                                  errors.collect('consent-form-image.dni')
                                "
                                data-vv-name="dni"
                                v-model="form.customer.dni"
                              >
                              </v-text-field
                            ></v-col>

                            <v-col class="py-0" cols="12" sm="6"
                              ><label>{{ $t("consent-form.date") }}:</label>

                              <v-menu
                                ref="menu3"
                                v-model="menu5"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    hide-details
                                    outlined
                                    dense
                                    :value="
                                      form.available_dates[0].date
                                        ? $d(
                                            new Date(
                                              form.available_dates[0].date
                                            ),
                                            'date2digits'
                                          )
                                        : ''
                                    "
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ><template v-slot:prepend-inner>
                                      <!--Aqui va el v-icon -->
                                      <v-icon
                                        small
                                        class="pt-0 pb-1"
                                        style="margin-top: 6px"
                                        color="primary"
                                        >$appointments</v-icon
                                      >
                                    </template></v-text-field
                                  >
                                </template>
                                <v-date-picker
                                  first-day-of-week="1"
                                  v-model="form.available_dates[0].date"
                                  no-title
                                  scrollable
                                  :min="today"
                                  color="primary"
                                  @input="menu5 = false"
                                >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            @click="
                              form.customer.image_rights = true;
                              dialog_image = false;
                            "
                            elevation="0"
                            >Aceptar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0 mb-4" style="font-size: 12px">
                    {{ $t("consent-form.medical_report_agreement") }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="4">
                    <label>{{ $t("consent-form.customer_dni_image") }}:</label>
                    <v-file-input
                      dense
                      class="inputs pointer"
                      v-model="dni_image"
                      v-validate="''"
                      :error-messages="errors.collect('consent-form.dni_image')"
                      data-vv-name="dni_image"
                      data-vv-validate-on="blur"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      accept="image/*"
                      :placeholder="formatTitleImage(form.customer.dni_image)"
                      clearable
                      @click.clear="dni_image = null"
                    >
                      <template v-slot:selection="{ text }">
                        {{ formatFile(text) }}
                      </template>
                      <template v-slot:append>
                        <v-icon
                          v-if="form.customer.dni_image && !dni_image"
                          small
                          class="pt-0 pb-1"
                          style="margin-top: 6px"
                          color="primary"
                          @click="form.customer.dni_image = null"
                          >mdi-close</v-icon
                        >
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="6" md="2"
                    ><label>{{ $t("consent-form.date") }}: </label>
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :hide-details="$vuetify.breakpoint.xs"
                          outlined
                          dense
                          :value="
                            form.available_dates[0].date
                              ? $d(
                                  new Date(form.available_dates[0].date),
                                  'date2digits'
                                )
                              : ''
                          "
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ><template v-slot:prepend-inner>
                            <!--Aqui va el v-icon -->
                            <v-icon
                              small
                              class="pt-0 pb-1"
                              style="margin-top: 6px"
                              color="primary"
                              >$appointments</v-icon
                            >
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="form.available_dates[0].date"
                        no-title
                        scrollable
                        :min="today"
                        color="primary"
                        @input="menu3 = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="3">
                    <pop-up-firma
                      :form="form"
                      name="clientSignature1"
                      :text="$t('consent-form.client_signature')"
                      :error="error.cs1"
                      @save="error.cs1 = false"
                    ></pop-up-firma>
                  </v-col>
                </v-row>
              </div>
              <v-row class="pa-3" justify="end">
                <v-btn
                  color="primary"
                  type="submit"
                  style="height: 30px"
                  elevation="0"
                  class="ml-2"
                >
                  {{ $t("next") }}
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-row>
            </form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <form
              action
              data-vv-scope="consent-form"
              @submit.prevent="validateFormInitial('consent-form')"
            >
              <h2>ESTUDIO</h2>
              <div class="borderRound pa-4">
                <v-row>
                  <div></div>
                  <v-col cols="12" >
                    <v-btn
                      elevation="0"
                      style="height: 30px"
                      @click="fetchConsentDefault"
                      >{{ $t("consent-form.load") }}</v-btn
                    >
                  </v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.studio_name") }}:</label>

                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'min:2'"
                      :error-messages="
                        errors.collect('consent-form.studio_name')
                      "
                      data-vv-name="studio_name"
                      v-model="form.studio.studio_name"
                      class="text-field"
                    >
                    </v-text-field
                  ></v-col>

                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.address") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'min:2'"
                      :error-messages="errors.collect('consent-form.address')"
                      data-vv-name="address"
                      v-model="form.studio.address"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.phone") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'min:2'"
                      :error-messages="errors.collect('consent-form.phone')"
                      data-vv-name="phone"
                      type="number"
                      v-on:keypress="isNumber($event)"
                      v-model="form.studio.phone"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col class="py-0" cols="12" sm="6" md="4" lg="3"
                    ><label>{{ $t("consent-form.email") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'email'"
                      :error-messages="errors.collect('consent-form.email')"
                      data-vv-name="email"
                      v-model="form.studio.user.email"
                    >
                    </v-text-field
                  ></v-col>

                  <v-col class="py-0" cols="12" sm="6" md="4"
                    ><label style="min-width: 200px !important"
                      >{{ $t("consent-form.sanitary_auth_number") }}:
                    </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form.sanitary_auth')
                      "
                      data-vv-name="sanitary_auth"
                      type="number"
                      v-on:keypress="isNumber($event)"
                      v-model="form.customer.sanitary_auth"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <pop-up-firma
                      :form="form"
                      name="estudioSignature1"
                      :text="$t('consent-form.estudio_signature')"
                    ></pop-up-firma>
                  </v-col>
                </v-row>
              </div>
              <h2>ARTISTA</h2>
              <div class="borderRound pa-4">
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.tattooer_name") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'min:2'"
                      :error-messages="
                        errors.collect('consent-form.tattoer_first_name')
                      "
                      data-vv-name="tattoer_first_name"
                      v-model="form.tattooer.user.first_name"
                    >
                    </v-text-field></v-col
                  ><v-col class="py-0" cols="12" sm="6" md="3"
                    ><label
                      >{{ $t("consent-form.tattooer_last_name") }}:
                    </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'min:2'"
                      :error-messages="
                        errors.collect('consent-form.tattoer_last_name')
                      "
                      data-vv-name="tattoer_last_name"
                      v-model="form.tattooer.user.last_name"
                    >
                    </v-text-field
                  ></v-col>

                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.tattooer_dni") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'min:2'"
                      :error-messages="
                        errors.collect('consent-form.tattoer_dni')
                      "
                      data-vv-name="tattoer_dni"
                      v-model="form.tattooer.dni"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <pop-up-firma
                      :form="form"
                      name="tattooerSignature1"
                      :text="$t('consent-form.tattooer_signature')"
                    ></pop-up-firma>
                  </v-col>
                </v-row>
              </div>
              <h2>SERVICIO</h2>
              <div class="borderRound pa-4">
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.body_laser_part") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'min:2'"
                      :error-messages="errors.collect('consent-form.body_part')"
                      data-vv-name="body_part"
                      v-model="form.body_part"
                    ></v-text-field
                    ><!--Se debería hacer un items de todas las partes del cuerpo, para así seleccionar la que necesites--></v-col
                  >

                  <v-col class="py-0" cols="12" sm="6" md="4"
                    ><label>{{ $t("consent-form.planned_sessions") }}: </label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form.planned_sessions')
                      "
                      data-vv-name="planned_sessions"
                      v-model="form.planned_sessions"
                      type="number"
                      v-on:keypress="isNumber($event)"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3"
                    ><label>{{ $t("consent-form.actual_session") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form.actual_session')
                      "
                      data-vv-name="actual_session"
                      v-model="form.actual_session"
                      type="number"
                      v-on:keypress="isNumber($event)"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col cols="12" md="3"
                    ><label>{{ $t("consent-form.anestesia") }}:</label
                    ><v-select
                      :items="items"
                      single-line
                      dense
                      outlined
                      hide-details
                      v-model="form.anestesia"
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="errors.collect('consent-form.anestesia')"
                      data-vv-name="anestesia"
                    ></v-select
                  ></v-col>
                  <v-col cols="12" md="3"
                    ><label>{{ $t("consent-form.name_anestesia") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      :disabled="form.anestesia == 0 || form.anestesia == null"
                      data-vv-validate-on="blur"
                      v-validate="
                        form.anestesia || form.anestesia == 1 ? 'min:2' : ''
                      "
                      :error-messages="
                        errors.collect('consent-form.name_anestesia')
                      "
                      data-vv-name="name_anestesia"
                      v-model="form.name_anestesia"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <!--Tabla tinta/colores...-->
                <v-radio-group
                  v-if="false"
                  v-model="form.type_form"
                  class="pl-2 mt-0 mb-3"
                  data-vv-name="type_form"
                  :error-messages="
                    errors.collect('inventorymovements-form.type_form')
                  "
                  data-vv-validate-on="blur"
                  v-validate="''"
                >
                  <v-radio color=" primary">
                    <template v-slot:label
                      ><div
                        style="
                        margin-top: 6px;
                        width: 100%;
                        white-space: normal !important;
                      "
                      >
                        {{ $t("consent-form.option_a") }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio color="primary" class="mt-2">
                    <template v-slot:label
                      ><div
                        style="
                        margin-top: 6px;
                        width: 100%;
                        white-space: normal !important;
                      "
                      >
                        {{ $t("consent-form.option_b") }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-row v-if="false">
                  <v-col class="pt-0 pb-6">
                    <v-simple-table width="650">
                      <thead align="center">
                        <tr>
                          <th class="text-center" min-width="100">
                            {{ $t("consent-form.piercing_used") }}
                          </th>
                          <th class="text-center" min-width="100">
                            {{ $t("consent-form.instrument_used") }}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(item, i) in form.inktable" :key="i">
                          <td v-if="i == 0">
                            <v-text-field
                              :hide-details="$vuetify.breakpoint.xs"
                              v-model="item.piercer_name"
                              data-vv-validate-on="blur"
                              v-validate="''"
                              :error-messages="
                                errors.collect('consent-form.ink_name_' + i)
                              "
                              :data-vv-name="'ink_name_' + i"
                            ></v-text-field>
                          </td>
                          <td v-else></td>
                          <td>
                            <v-text-field
                              :hide-details="$vuetify.breakpoint.xs"
                              v-model="item.instrument"
                              data-vv-validate-on="blur"
                              v-validate="''"
                              :error-messages="
                                errors.collect('consent-form.colors_' + i)
                              "
                              :data-vv-name="'colors_' + i"
                            ></v-text-field>
                          </td>

                          <td>
                            <v-btn icon @click="del(i)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="1">
                            <v-btn
                              @click="add"
                              color="primary"
                              elevation="0"
                              style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                            >
                              <v-icon>mdi-plus</v-icon>
                              {{ $t("consent-form.add_row") }}
                            </v-btn>
                          </td>
                        </tr>
                      </tfoot>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <!--Tabla material/utensilios...-->
                <v-row v-if="false">
                  <v-col class="pt-0 pb-6">
                    <v-simple-table width="650">
                      <thead align="center">
                        <tr>
                          <th class="text-center" min-width="100">
                            {{ $t("consent-form.description") }}
                          </th>
                          <th class="text-center" min-width="100">
                            {{ $t("consent-form.sterilization_date") }}
                          </th>
                          <th class="text-center" min-width="120">
                            {{ $t("consent-form.expire_date") }}
                          </th>
                          <th class="text-center" min-width="100">
                            {{ $t("consent-form.num_reuses") }}
                          </th>
                          <th class="text-center" min-width="100">
                            {{ $t("consent-form.traceability") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in form.materialtable" :key="i">
                          <td>
                            <v-text-field
                              :hide-details="$vuetify.breakpoint.xs"
                              v-model="item.description"
                              data-vv-validate-on="blur"
                              v-validate="''"
                              :error-messages="
                                errors.collect('consent-form.description_' + i)
                              "
                              :data-vv-name="'description_' + i"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              :hide-details="$vuetify.breakpoint.xs"
                              v-model="item.sterilization_date"
                              data-vv-validate-on="blur"
                              v-validate="''"
                              :error-messages="
                                errors.collect(
                                  'consent-form.sterilization_date_' + i
                                )
                              "
                              :data-vv-name="'sterilization_date_' + i"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              :hide-details="$vuetify.breakpoint.xs"
                              v-model="item.expire_date"
                              data-vv-validate-on="blur"
                              v-validate="''"
                              :error-messages="
                                errors.collect(
                                  'consent-form.expire_date_material_' + i
                                )
                              "
                              :data-vv-name="'expire_date_material_' + i"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              :hide-details="$vuetify.breakpoint.xs"
                              v-model="item.num_reuses"
                              data-vv-validate-on="blur"
                              v-validate="''"
                              :error-messages="
                                errors.collect('consent-form.num_reuses_' + i)
                              "
                              :data-vv-name="'num_reuses_' + i"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              :hide-details="$vuetify.breakpoint.xs"
                              v-model="item.traceability"
                              data-vv-validate-on="blur"
                              v-validate="''"
                              :error-messages="
                                errors.collect('consent-form.traceability_' + i)
                              "
                              :data-vv-name="'traceability_' + i"
                            ></v-text-field>
                          </td>

                          <td>
                            <v-btn icon @click="delMaterial(i)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="1">
                            <v-btn
                              @click="addMaterial"
                              color="primary"
                              elevation="0"
                              style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                            >
                              <v-icon>mdi-plus</v-icon>
                              {{ $t("consent-form.add_row") }}
                            </v-btn>
                          </td>
                        </tr>
                      </tfoot>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="4"
                    ><label>{{ $t("consent-form.session_duration") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="errors.collect('consent-form.duration')"
                      data-vv-name="duration"
                      v-model="form.session.duration"
                    >
                    </v-text-field
                  ></v-col>

                  <v-col class="py-0" cols="12" sm="6" md="4"
                    ><label>{{ $t("consent-form.cicatrization_time") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form.cicatrization_time')
                      "
                      data-vv-name="cicatrization_time"
                      v-model="form.session.cicatrization_time"
                    >
                    </v-text-field
                  ></v-col>

                  <v-col class="py-0" cols="12" sm="6" md="4"
                    ><label>{{ $t("consent-form.budget") }}:</label>
                    <v-text-field
                      :hide-details="$vuetify.breakpoint.xs"
                      outlined
                      dense
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form.price_budget')
                      "
                      data-vv-name="price_budget"
                      v-model="form.session.price_budget"
                      type="number"
                      v-on:keypress="isNumber($event)"
                      append-icon="mdi-currency-eur"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="7"
                    ><label
                      :style="
                        $vuetify.breakpoint.xsOnly
                          ? 'line-height: 1.5;display: block;white-space: normal; margin-bottom: 9.5px'
                          : ''
                      "
                      >{{ $t("consent-form.piercer_measures") }}:</label
                    >

                    <v-textarea
                      style="border-radius: 10px"
                      outlined
                      data-vv-validate-on="blur"
                      v-validate="''"
                      :error-messages="
                        errors.collect('consent-form.healing_measures')
                      "
                      data-vv-name="healing_measures"
                      v-model="form.session.healing_measures"
                      rows="2"
                    >
                    </v-textarea
                  ></v-col>
                </v-row>
              </div>

              <div class="pa-3 text-right">
                <v-btn
                  color="primary"
                  @click="page('back')"
                  style="height: 30px"
                  outlined
                >
                  <v-icon>mdi-arrow-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
                <v-btn
                  v-if="pop_up"
                  @click="$emit('close')"
                  color="primary"
                  style="height: 30px"
                  elevation="0"
                  class="ml-2"
                >
                  {{ $t("next") }}
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  type="submit"
                  color="primary"
                  style="height: 30px"
                  elevation="0"
                  class="ml-2"
                >
                  {{ $t("next") }}
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </form>
          </v-stepper-content>
          <v-stepper-content step="3" v-if="false">
            <form
              action
              data-vv-scope="consent-form-image"
              @submit.prevent="validateFormInitial('consent-form-image')"
            >
              <p
                v-text="
                  $t('consent-form.image_rights.p1', {
                    studio_name: form.studio.studio_name,
                  })
                "
              />
              <p
                v-text="
                  $t('consent-form.image_rights.p2', {
                    studio_name: form.studio.studio_name,
                  })
                "
              />

              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.name") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    cols="12"
                    sm="6"
                    md="3"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form-image.first_name')
                    "
                    data-vv-name="first_name"
                    v-model="form.customer.user.first_name"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.surname") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    cols="12"
                    sm="6"
                    md="3"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form-image.last_name')
                    "
                    data-vv-name="last_name"
                    v-model="form.customer.user.last_name"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.dni") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    cols="12"
                    sm="6"
                    md="3"
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="errors.collect('consent-form-image.dni')"
                    data-vv-name="dni"
                    v-model="form.customer.dni"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="2"
                  ><label>{{ $t("consent-form.date") }}:</label>

                  <v-menu
                    ref="menu3"
                    v-model="menu5"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        :value="
                          form.available_dates[0].date
                            ? $d(
                                new Date(form.available_dates[0].date),
                                'date2digits'
                              )
                            : ''
                        "
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ><template v-slot:prepend-inner>
                          <!--Aqui va el v-icon -->
                          <v-icon
                            small
                            class="pt-0 pb-1"
                            style="margin-top: 6px"
                            color="primary"
                            >$appointments</v-icon
                          >
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="form.available_dates[0].date"
                      no-title
                      scrollable
                      :min="today"
                      color="primary"
                      @input="menu5 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <pop-up-firma
                    btnStyle="margin-top: 18px"
                    :form="form"
                    name="clientSignature3"
                    :text="$t('consent-form.client_signature')"
                    :error="error.cs3"
                    @save="error.cs3 = false"
                  ></pop-up-firma>
                </v-col>
              </v-row>
              <p
                v-if="form.customer.minus16_incapacitated"
                v-text="
                  $t('consent-form.image_rights.p3', {
                    studio_name: form.studio.studio_name,
                  })
                "
              />
              <v-row v-if="form.customer.minus16_incapacitated">
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.legal_tutor_name") }}:</label>

                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    data-vv-name="legal_tutor_first_name"
                    :error-messages="
                      errors.collect(
                        'consent-form-image.legal_tutor_first_name'
                      )
                    "
                    cols="12"
                    sm="6"
                    md="3"
                    v-model="form.customer.legal_tutor.first_name"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.legal_tutor_surname") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    data-vv-name="legal_tutor_last_name"
                    :error-messages="
                      errors.collect('consent-form-image.legal_tutor_last_name')
                    "
                    cols="12"
                    sm="6"
                    md="3"
                    v-model="form.customer.legal_tutor.last_name"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.dni") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    cols="12"
                    sm="6"
                    md="3"
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="errors.collect('consent-form-image.dni')"
                    data-vv-name="dni"
                    v-model="form.customer.legal_tutor.dni"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="2"
                  ><label>{{ $t("consent-form.date") }}:</label>
                  <v-menu
                    ref="menu3"
                    v-model="menu6"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :hide-details="$vuetify.breakpoint.xs"
                        outlined
                        dense
                        :value="
                          form.available_dates[0].date
                            ? $d(
                                new Date(form.available_dates[0].date),
                                'date2digits'
                              )
                            : ''
                        "
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ><template v-slot:prepend-inner>
                          <!--Aqui va el v-icon -->
                          <v-icon
                            small
                            class="pt-0 pb-1"
                            style="margin-top: 6px"
                            color="primary"
                            >$appointments</v-icon
                          >
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="form.available_dates[0].date"
                      no-title
                      scrollable
                      :min="today"
                      color="primary"
                      @input="menu6 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col> </v-row
              ><v-row v-if="form.customer.minus16_incapacitated">
                <v-col cols="12" md="3">
                  <pop-up-firma
                    btnStyle="margin-top: 18px"
                    :form="form"
                    name="clientSignature4"
                    :text="$t('consent-form.client_tutor_signature')"
                  ></pop-up-firma>
                </v-col>
              </v-row>

              <v-row class="pa-3" justify="end">
                <v-btn
                  color="primary"
                  @click="page('back')"
                  style="height: 30px"
                  outlined
                >
                  <v-icon>mdi-arrow-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
                <v-btn
                  v-if="section != null"
                  color="primary"
                  type="submit"
                  style="height: 30px"
                  elevation="0"
                  class="ml-2"
                >
                  {{ $t("next") }}
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  type="submit"
                  style="
                    width: 130px !important;
                    color: #363533;
                    height: 30px;
                    padding-top: 10px !important;
                    margin-left: 10px;
                  "
                  elevation="0"
                >
                  <v-icon small style="margin-right: 5px" size="14px"
                    >$save</v-icon
                  >
                  {{ $t("save", { name: $t("") }) }}
                </v-btn>
              </v-row>
            </form>
          </v-stepper-content>
          <v-stepper-content v-if="section != null" step="4">
            <VisualForm
              :section="section"
              :form="form.extra"
              :edit="false"
              @validated="validateAll()"
              @back="page('back')"
            ></VisualForm>
          </v-stepper-content>
        </v-stepper-items>
      </base-material-card>
    </v-stepper>
    <v-dialog v-model="error_dialog" width="440">
      <v-card>
        <div class="close">
          <v-btn icon @click="error_dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title style="font-size: 22px !important">
          {{ $t("save_ok_consent_form") }}
        </v-card-title>
        <v-card-text>
          <template v-if="errors_c.length">
            <p class="text-decoration-underline">
              {{ $t("consent-form.customer_camps") }}
            </p>
            <ul>
              <li v-for="(camp, i) in errors_c" :key="i">
                {{ $t("consent-form." + camp) }}
              </li>
            </ul>
          </template>
          <template v-if="errors_s.length">
            <p class="text-decoration-underline">
              {{ $t("consent-form.studio_camps") }}
            </p>
            <ul>
              <li v-for="(camp, i) in errors_s" :key="i">
                {{ $t("consent-form." + camp) }}
              </li>
            </ul>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="error_dialog = false"
            style="padding: 20px 30px !important; font-size: 1.0625em"
            elevation="0"
            outlined
            >{{ $t("to_complete") }}</v-btn
          >

          <v-btn
            @click="savePDF"
            style="padding: 20px 30px !important; font-size: 1.0625em"
            elevation="0"
            >{{ $t("generate_pdf", { name: "" }) }}</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as moment from "moment";
import { mask } from "vue-the-mask";
export default {
  props: {
    pop_up: {
      type: Boolean,
      default: false,
    },
  },
  name: "ConsentFormPiercerView",
  mounted() {
    if (this.$store.getters["auth/isLoggedIn"]) this.fetchSetting();
    this.today = moment().format("YYYY-MM-DD");
    this.fetchAppointment();
  },
  directives: {
    mask,
  },
  components: {
    vueSignature: () => import("vue-signature"),
    PopUpFirma: () => import("@/components/ui/PopUpFirma"),
    VisualForm: () =>
      import("@/components/user/settings/profile/VisualConsentForm"),
  },
  computed: {
      URL(){
      return `${window.location.origin}/?#/inscription${this.$route.fullPath}`;
    },
    endDisabled() {
      if (this.form.customer.minus16_incapacitated) {
        return (
          this.clientSignature1 === undefined ||
          this.clientSignature2 === undefined
        );
      }
      return this.clientSignature1 === undefined;
    },
    canSettingsForm() {
      if (this.$store.getters["auth/isStudio"])
        return (
          this.settingsForm !== null ||
          this.$store.state.auth.user.studio.consent_form_piercer !== null
        );
      return false;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    "form.clientSignature1": {
      handler() {
        this.error.cs1 = false;
      },
      deep: true,
    },
    "form.customer.attached_information": {
      handler() {
        this.error.anex = false;
      },
      deep: true,
    },
  },
  methods: {
    ...mapGetters("app", ["addObject"]),
    formatFile(data) {
      if (data && data !== "null") {
        data = data.split("/");
        data = data[data.length - 1];
        let type = data.split(".");
        type = type[type.length - 1];
        return `${data.substr(0, 4)}...${type}`;
      }
    },

    formatTitleImage(name) {
      console.log("NAME", name);
      if (name) {
        let newName = name.split("/");
        newName = newName[newName.length - 1];
        return newName;
      }
    },

    validateOne(scope) {
      this.$nextTick(() => {
        this.$validator.validate(scope);
      });
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("users", ["settingUpdate", "getSetting"]),

    fetchSetting() {
      //console.log(this.section);
      this.getSetting({ key: "cf_section" }).then((boxes) => {
        if (boxes.data.value) {
          this.section = JSON.parse(boxes.data.value);
          this.form.extra = {};
          this.loading = false;
          this.validated_section = true;
        }
      });
    },
    loadFromSettings() {
      if (this.settingsForm !== null) {
        this.form = this.settingsForm;
        console.log(this.form);
      } else {
        console.log(this.$store.state.auth.user.studio.consent_form_piercer);
        this.form = this.$store.state.auth.user.studio.consent_form_piercer;
      }
      this.form.available_dates[0].date = moment().format("YYYY-MM-DD hh:mm");
    },
    up() {
      this.$vuetify.goTo(this.$refs.headerStepper, {});
    },
    del(index) {
      //console.log("delete", index);

      this.form.inktable.splice(index, 1);
    },
    delMaterial(index) {
      //console.log("delete", index);
      this.form.materialtable.splice(index, 1);
    },
    ...mapActions("appointments", [
      "getAppointment",
      "generatePdf",
      "saveConsentAndCheck",
    ]),
    fetchAppointment() {
      this.getAppointment(this.$route.params.id).then((data) => {
        this.appointment = data;

        if (data.studio) this.processed = true;
        if (data.subservice && data.subservice.consent_form)
          this.settingsForm = data.subservice.consent_form;
        this.settingsForm = data.studio.consent_form;
        //tattooer
        this.form.appointment_id = data.id;
        this.form.customer_id = this.$route.params.customer_id;
        this.form.date_id = this.$route.params.date_id;

        let c = data.extra_customers.find(
          (x) => x.id === this.form.customer_id
        );

        console.log("customer", c);
        if (c) data.customer = c;
        console.log("data", data);

        if (data.tattooer) {
          this.form.tattooer.id = data.tattooer.id;
          this.form.tattooer.user.first_name = data.tattooer.user.first_name;
          this.form.tattooer.user.last_name = data.tattooer.user.last_name;
          this.form.tattooer.user.email = data.tattooer.user.email;
          this.form.tattooer.dni = data.tattooer.DNI;
          console.log("tattooer", data.tattooer);

         let sigTattooer = data.tattooer.user.setting.find(
            (x) => x.key === "artist_signature"
          );
          if (sigTattooer) {
            console.log("SIGTATTOER");
            this.form.tattooerSignature1 = sigTattooer.value;
            console.log(this.form.tattooerSignature1);
          }
          //FIRMA ESTUDIO
          let sigEstudio = data.studio.user.setting.find(
            (x) => x.key === "artist_signature"
          );
          if (sigEstudio) {
            console.log("SIGESTUDIO");
            this.form.estudioSignature1 = sigEstudio.value;
            console.log(this.form.estudioSignature1);
          }

          this.form.tattooer.formation = data.tattooer.formation;
        }

        this.form.tattooer.phone = data.studio.user.phone;
        //customer
        this.form.customer.sanitary_auth = data.studio.sanitary_auth;
        this.form.customer.user.first_name = data.customer.user.first_name;
        this.form.customer.user.last_name = data.customer.user.last_name;
        this.form.customer.birthdate = data.customer.birthdate;
        this.form.customer.dni = data.customer.dni;
        this.form.customer.address = data.customer.address;
        this.form.customer.user.phone = data.customer.user.phone;
        this.form.customer.user.email = data.customer.user.email;
        this.form.customer.allergies = data.customer.allergies;
        this.form.customer.medicines = data.customer.medicines;
        this.form.customer.diseases = data.customer.diseases;
        this.form.customer.medical_other = data.customer.medical_other;
        this.form.customer.attached_information =
          data.customer.attached_information;
        this.form.customer.howMeet = data.customer.howMeet;

        //studio
        this.form.studio.id = data.studio.id;
        this.form.studio.address = data.studio.address;
        this.form.studio.studio_name = data.studio.studio_name;
        this.form.studio.record_number = data.studio.record_number;
        this.form.studio.phone = data.studio.user.phone;
        this.form.studio.user.email = data.studio.user.email;
        console.log("%c%s", "color: #997326", this.form.studio.user.email);
        console.log(data.studio.user.email);
        this.form.studio.user.first_name = data.studio.user.first_name;
        this.form.studio.user.last_name = data.studio.user.last_name;

        this.studio = data.studio;
        //other
        //this.form.available_dates[0].date = data.available_dates[0].date;
        if (data.body_part)
          this.form.body_part = data.body_part.replace("_", " ");
        /*
        this.form.actual_session = data.actual_session;//TODO studio config
        this.form.planned_sessions = data.planned_sessions;//TODO studio config
        //this.form.session.duration = data.session.healing_measures;//TODO studio config
        //this.form.session.price_budget = data.session.healing_measures;//TODO studio config
        //this.form.session.healing_measures = data.session.healing_measures;//TODO studio config
        */

        let consent = data.consent_form.find(
          (x) =>
            x.customer_id == this.form.customer_id || x.customer_id === null
        );

        if (consent) data.consent_form = consent;
        else data.consent_form = null;

        if (data.consent_form) {
          //console.log("IT HAS OLD CONSENT FORM");
          //2a Part
          if (data.consent_form.tattooer_first_name)
            this.form.tattooer.user.first_name =
              data.consent_form.tattooer_first_name;
          if (data.consent_form.tattooer_last_name)
            this.form.tattooer.user.last_name =
              data.consent_form.tattooer_last_name;
          if (data.consent_form.studio_address)
            this.form.studio.address = data.consent_form.studio_address;
          if (data.consent_form.tattooer_phone)
            this.form.tattooer.phone = data.consent_form.tattooer_phone;
          if (data.consent_form.tattooer_email)
            this.form.tattooer.user.email = data.consent_form.tattooer_email;
          if (data.consent_form.sanitary_auth)
            this.form.customer.sanitary_auth = data.consent_form.sanitary_auth;
          if (data.consent_form.tattooer_dni)
            this.form.tattooer.dni = data.consent_form.tattooer_dni;
          if (data.consent_form.body_part)
            this.form.body_part = data.consent_form.body_part.replace("_", " ");

          if (data.consent_form.planned_sessions)
            this.form.planned_sessions = data.consent_form.planned_sessions;
          if (data.consent_form.actual_session)
            this.form.actual_session = data.consent_form.actual_session;
          if (data.consent_form.inktable)
            this.form.inktable = data.consent_form.inktable;
          if (data.consent_form.materialtable)
            this.form.materialtable = data.consent_form.materialtable;
          if (data.consent_form.session_duration)
            this.form.session.duration = data.consent_form.session_duration;
          if (data.consent_form.session_cicatrization_time)
            this.form.session.cicatrization_time =
              data.consent_form.session_cicatrization_time;
          if (data.consent_form.session_price_budget)
            this.form.session.price_budget =
              data.consent_form.session_price_budget;
          if (data.consent_form.session_healing_measures)
            this.form.session.healing_measures =
              data.consent_form.session_healing_measures;
          //this.form.available_dates[0].date = data.consent_form.date;
          //Signature tattoer

          //1a part
          this.form.customer.user.first_name =
            data.consent_form.customer_first_name;
          this.form.customer.user.last_name =
            data.consent_form.customer_last_name;
          this.form.customer.birthdate = data.consent_form.customer_birthdate;
          this.form.customer.dni = data.consent_form.customer_dni;
          this.form.customer.address = data.consent_form.customer_address;
          this.form.customer.country = data.consent_form.customer_country;
          this.form.customer.user.phone = data.consent_form.customer_phone;
          this.form.customer.user.email = data.consent_form.customer_email;
          this.form.customer.attached_information = "true";
          this.form.customer.treatment_tips = "true";
          this.form.clientSignature1 = data.consent_form.clientSignature1;
          this.form.clientSignature2 = data.consent_form.clientSignature2;
          this.form.clientSignature3 = data.consent_form.clientSignature3;
          this.form.tattooerSignature1 = data.consent_form.tattooerSignature1;
          this.form.estudioSignature1 = data.consent_form.estudioSignature1;
          this.form.customer.howMeet = data.consent_form.howMeet;
          this.form.customer.dni_image = data.consent_form.dni_image;
          this.form.customer.image_rights = data.consent_form.image_rights;

          //medicine
          this.form.customer.medical_report = data.consent_form.medical_report;
          this.form.customer.allergies = data.consent_form.customer_allergies;
          this.form.customer.medicines = data.consent_form.customer_medicines;
          this.form.customer.diseases = data.consent_form.customer_diseases;
          this.form.customer.medical_other =
            data.consent_form.customers_medical_other;

          //16yo
          this.form.customer.minus16_incapacitated = data.consent_form.minus16;
          this.form.customer.legal_tutor.first_name =
            data.consent_form.tutor_first_name;
          this.form.customer.legal_tutor.last_name =
            data.consent_form.tutor_last_name;
          this.form.customer.legal_tutor.birthdate =
            data.consent_form.tutor_birthdate;
          this.form.customer.legal_tutor.dni = data.consent_form.tutor_dni;
          this.form.customer.legal_tutor.address =
            data.consent_form.tutor_address;
          this.form.customer.legal_tutor.country =
            data.consent_form.tutor_country;
          this.form.customer.legal_tutor.phone = data.consent_form.tutor_phone;
          this.form.customer.legal_tutor.email = data.consent_form.tutor_email;
          this.form.customer.legal_tutor.tutor_type =
            data.consent_form.tutor_type;
          this.form.type_form = data.consent_form.type_form ? 1 : 0;
        }
        this.form.available_dates[0].date = this.date = new Date()
          .toISOString()
          .substr(0, 10);

        if (this.form.inktable === null) this.form.inktable = [];
        if (this.form.materialtable === null) this.form.materialtable = [];
      });
    },


    ...mapActions("consent_form_default", [
      "addConsentFormDefault",
      "getConsentFormDefault",
    ]),

    fetchConsentDefault() {
      console.log("FETCH CONSENT DEFAULT");
      console.log("SERVICE_ID", this.appointment.service_id);
      this.getConsentFormDefault({
        service_id: this.appointment.service_id,
        user_id: this.$store.state.auth.user.id,
      }).then((data) => {
        console.log("DATA", data);
        if (data.success) {
          //this.form = data.data.consent_form_default;

          //studio
          if (data.data.consent_form_default.studio.id)
            this.form.studio.id = data.data.consent_form_default.studio.id;
          if (data.data.consent_form_default.studio.address)
            this.form.studio.address =
              data.data.consent_form_default.studio.address;
          if (data.data.consent_form_default.studio.studio_name)
            this.form.studio.studio_name =
              data.data.consent_form_default.studio.studio_name;
          if (data.data.consent_form_default.studio.record_number)
            this.form.studio.record_number =
              data.data.consent_form_default.studio.record_number;
          if (data.data.consent_form_default.studio.phone)
            this.form.studio.phone =
              data.data.consent_form_default.studio.phone;
          if (data.data.consent_form_default.studio.email)
            this.form.studio.user.email =
              data.data.consent_form_default.studio.email;
          if (data.data.consent_form_default.customer.sanitary_auth)
            this.form.customer.sanitary_auth =
              data.data.consent_form_default.customer.sanitary_auth;

          //ARTISTA
          if (data.data.consent_form_default.tattooer.user.first_name)
            this.form.tattooer.user.first_name =
              data.data.consent_form_default.tattooer.user.first_name;
          if (data.data.consent_form_default.tattooer.user.last_name)
            this.form.tattooer.user.last_name =
              data.data.consent_form_default.tattooer.user.last_name;
          if (data.data.consent_form_default.tattooer.dni)
            this.form.tattooer.dni =
              data.data.consent_form_default.tattooer.dni;

          //SERVICIO

          //medicine
          if (data.data.consent_form_default.body_part)
            this.form.body_part = data.data.consent_form_default.body_part;
          if (data.data.consent_form_default.planned_sessions)
            this.form.planned_sessions =
              data.data.consent_form_default.planned_sessions;
          if (data.data.consent_form_default.actual_session)
            this.form.actual_session =
              data.data.consent_form_default.actual_session;
          if (data.data.consent_form_default.anestesia)
            this.form.anestesia = data.data.consent_form_default.anestesia;
if (data.data.consent_form_default.name_anestesia)
            this.form.name_anestesia = data.data.consent_form_default.name_anestesia;

          //SESSION  
          if (data.data.consent_form_default.session.duration)
            this.form.session.duration =
              data.data.consent_form_default.session.duration;
          if (data.data.consent_form_default.session.price_budget)
            this.form.session.price_budget =
              data.data.consent_form_default.session.price_budget;
          if (data.data.consent_form_default.session.cicatrization_time)
            this.form.session.cicatrization_time =
              data.data.consent_form_default.session.cicatrization_time;
          if (data.data.consent_form_default.session.treatment_tips)
            this.form.session.treatment_tips =
              data.data.consent_form_default.session.treatment_tips;
        }
      });
    },


    page(scope) {
      if (scope === "next") {
        this.e1++;
      } else {
        this.e1--;
      }
    },
    validateFormInitial(scope) {
      //console.log("validando");
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        //console.log(result, this.errors);
        if (result) {
          ////console.log(data);
          //* LLamar post backend
          if (scope === "consent-form") {
            this.validateAll();
          } else {
            this.validateNotFinal();
          }
          //this.$emit("validated", this.user);
          //return true;
        } else {
          //return false;
        }
      });
    },

    validateNotFinal() {
      let form = new FormData();
      this.addObject()(form, this.form);
      form.append("dni_image", this.dni_image);
      console.log("DNI_IMG", this.dni_image);

      this.saveConsentAndCheck(form).then((data) => {
        //console.log(this.form);
        console.log("FORM", this.form);
        console.log("DATA", data);
        this.page("next");
      });
    },
    validateAll() {
      let form = new FormData();
      this.addObject()(form, this.form);
      form.append("dni_image", this.dni_image);
      this.saveConsentAndCheck(form).then((data) => {
        //console.log(this.form);
        if (this.$store.getters["auth/isLoggedIn"]) {
          //this.$router.back();
          if (data.save_file) this.savePDF();
          else {
            this.errors_c = data.errors_c;
            this.errors_s = data.errors_s;
            this.error_dialog = true;
          }
        } else {
          this.formDone = true;
        }
      });
    },
    savePDF() {
      let form = new FormData();
      this.addObject()(form, this.form);
      form.append("dni_image", this.dni_image);

      this.generatePdf(form).then((data) => {
        this.$alert(this.$t("consent-form.ok"), "", "");
        this.$router.back();
      });
    },
    validateFormDialog(scope) {
      //console.log("validando");
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        //console.log(result, this.errors);
        if (result) {
          this.dialog = false;
          this.e2 = 1;
          ////console.log(data);
          //this.$emit("validated", this.user);
          //return true;
        } else {
          //return false;
        }
      });
    },
    validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      //console.log("validando");
      for (let i = 1; i <= 3; i++) {
        this.form["tattooerSignature" + i] = this.$refs["tattooerSignature" + i]
          ? this.$refs["tattooerSignature" + i].save("image/svg+xml")
          : null;
        this.form["clientSignature" + i] = this.$refs["clientSignature" + i]
          ? this.$refs["clientSignature" + i].save("image/svg+xml")
          : null;
        this.form["estudioSignature" + i] = this.$refs["estudioSignature" + i]
          ? this.$refs["estudioSignature" + i].save("image/svg+xml")
          : null;
      }

      this.$validator.validateAll(scope).then((result) => {
        //console.log(result, this.errors);
        if (result) {
          //* LLamar post backend
          let form = new FormData();
          this.addObject()(form, this.form);
          form.append("dni_image", this.dni_image);

          this.generatePdf(form).then((data) => {
            //console.log(data);
          });
          //this.$emit("validated", this.user);
          //return true;
        } else {
          //return false;
        }
      });
    },
    add() {
      this.form.inktable.push({});
    },
    addMaterial() {
      this.form.materialtable.push({});
    },

    //vue-signature
    save(refName) {
      var _this = this;
      // var png = _this.$refs.signature.save();
      // var jpeg = _this.$refs.signature.save("image/jpeg");
      var svg = this.$refs[refName].save("image/svg+xml");
      ////console.log(png);
      ////console.log(jpeg);
      //console.log(svg);
    },
    clear(refName) {
      var _this = this;
      _this.$refs[refName].clear();
    },
  },
  data() {
    return {
      dialogConsentForm : false,
      dni_image: null,
      howMeetItems: ["Online", "Amigos","Cliente","Otros"],
      sub_service_annex: null,
      dialog_image: false,
      errors_c: [],
      errors_s: [],
      error_dialog: false,
      formDone: false,
      section: null,
      validated_section: false,
      today: null,
      processed: false,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      dialog: false,
      resevationDone: false,
      e1: 1,
      appointment: {},
      studio: {},
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      e2: 1,
      form: {
        customer: {
          legal_tutor: { tutor_type: "father" },
          user: {},
          medical_report: false,
          minus16_incapacitated: false,
          attached_information: false,
          howMeet: null,
          image_rights: false,
          dni_image: null,
        },
        tattooer: { user: {} },
        studio: { user: {}, timetable: [[[]]] },
        available_dates: [{}],
        design: { photos: [], urls: [] },
        inktable: [{}],
        materialtable: [{}],
        session: { treatment_tips: false },
        type: "laser",
        type_form: null,
        clientSignature1: null,
        clientSignature2: null,
        clientSignature3: null,
        clientSignature4: null,
        tattooerSignature1: null,
        estudioSignature1: null,
      },
      settingsForm: null,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      error: { anex: false, cs1: false, cs3: false },
    };
  },
};
</script>
<style lang="sass" scoped>
.firma
  border: 2px solid rgb(217,178,105)
  border-radius: 20px
  overflow: hidden
  margin-bottom: 5px

th
  font-size: 12px !important
.v-dialog__content--active
  margin-left: 20%
  max-width: 60% !important

p, li
  color: white
ul
  padding-bottom: 20px

.close .v-btn
  top: 0
  right: 0
  position: absolute
  z-index: 3
</style>
<style lang="sass">
#consent-form
  .v-input--selection-controls__input .v-icon
    margin-top: 8px !important
  @media(min-width: 600px)
    label
      height: 30px !important
      display: block
      white-space: nowrap

  .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner
    margin-top: 2px
  .v-select .v-select__selections
    margin-top: -10px
  .v-input input

    padding-bottom: 19px
  .v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea
    margin-top: 2px

  .v-text-field.v-input--dense:not(.v-textarea) fieldset

    margin-top: 0 !important

  .v-data-table
    table
      min-width: 650px
  .v-input--checkbox label
    font-size: 12px

  @media (max-width: 450px)

    .v-stepper--alt-labels .v-stepper__step

      flex-basis: 0px
</style>
